import React, { Component } from 'react';
import {Form, InputGroup,Button, FormControl} from 'react-bootstrap';
import {queryhoteldata, putData} from '../../Module/AWS/DynamoDB/index';
import sha256 from "sha256";


  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert:'',
            firstdate:'2020-04-05', //'',
            enddate:'2020-04-07', //'',
            name:'chang',
            id:'T123123789',
            tel:'0910672990',
            birthday:'1981-12-14',
            email:'futaitung@gmail.com',
            membercount:'2',
            member:[],
            read:false,
            sumbitState:false,
            alertemail:'優惠活動通知使用',
            alertsubmit:'',
            guestsn:'',
            hotelset:{},//{name:'感覺台東民宿', data:{tel:'0910672990', address:'台東縣台東市中興路二段122號', lineID:'', wechat:''},notice:{notice_covid19:'', notice_checkin:'', }, setting:{type:'base'}},
            hotelcity:'',
            hotelName:'',
            hoteltel:'',
            hoteladdress:'',
            hotelline:'',
            hotelwechat:'',
            hotelfb:'',
            hotelIG:'',
            hotelsn:'',
            hotelsetting:{type:'base',everyguest:{name:'', id:'', birthday:''}}
        }        
    }
    
    componentDidMount() {
        if(this.state.hotelsn===''){this.loadData();}
    }
    
    componentWillUnmount() {

    }

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    loadData=async ()=>{

        var data = await queryhoteldata(this.props.hotel);

            // console.log(data);
            
        if (data.length===0){

        }else if(data.length>0){
            var jdata= data[0];

            this.setState({hotelname:jdata.hotelname,
                hoteltel:jdata.hoteldata.tel?jdata.hoteldata.tel:"",
                hoteladdress:jdata.hoteldata.address?jdata.hoteldata.address:"",
                hotelemail:jdata.hoteldata.email?jdata.hoteldata.email:"",
                hotelline:jdata.hoteldata.lineID?jdata.hoteldata.lineID:"",
                hotelwechat:jdata.hoteldata.wechat?jdata.hoteldata.wechat:"",
                hotelfb:jdata.hoteldata.fb?jdata.hoteldata.fb:"",
                hotelIG:jdata.hoteldata.ig?jdata.hoteldata.ig:"",
                hotelsn:jdata.sn?jdata.sn:"",
                hotelpageset:jdata.pageset?jdata.pageset:"",
                hotelnoticeset:jdata.noticeset?jdata.noticeset:"",
                hotelnum:jdata.num?jdata.num:"",
                hotelcity:jdata.city?jdata.city:""
            });
        }
    }

    handlerKeyChange = (e,t)=>{
        switch (t) {
            case 'firstdate':
                console.log(e.target.value);
                this.setState({firstdate:e.target.value},()=>{this.submitCheck()});
                break;
            case 'enddate':
                this.setState({enddate:e.target.value},()=>{this.submitCheck()});
                break;
            case 'name':
                this.setState({name:e.target.value},()=>{this.submitCheck()});
                break;
            case 'tel':
                this.setState({tel:e.target.value},()=>{this.submitCheck()});
                break;
            case 'birthday':
                this.setState({birthday:e.target.value});
                break;
            case 'id':
                this.setState({id:e.target.value},()=>{this.submitCheck()});
                break;
            case 'email':
                this.setState({email:e.target.value});
                break;
            case 'membercount':
                let count = e.target.value;
                if (this.state.hotelsetting.type==='base'){
                    this.setState({membercount:e.target.value});
                }else{
                    if (count>20){return false;}
                    let member = []
                    for (let i=0; i<e.target.value; i+=1){
                        if (i===0){
                            member.push({'index':i,'name':this.state.name,'id':this.state.id,'birthday':this.state.birthday});
                        }else{                        
                            member.push({'index':i,'name':'','id':'','birthday':''});                    
                        }
                    }
                    this.setState({membercount:e.target.value,member:member});
                }
                break;
            default:
                console.log('error_handlerKeyChange');
        }
    }

    submitCheck=()=>{
        var vlock = false;  // 預設為false，若全數通過驗證，將disabled設為關閉(false)
        if (this.state.tel.length <9){vlock=true}
        
        if (this.state.name.length <1){vlock=true}
        
        if (this.state.id.length < 9){vlock=true}
        
        if (this.state.firstdate ==="" || this.state.enddate === "" || this.state.birthday ===""){vlock=true}
        
        this.setState({"sumbitState":vlock});
        
        // if (vlock){this.setState({"sumbitState":false});}else{this.setState({"sumbitState":true});}
    }

    handlerKeyChangeSub = (e,index,t)=>{
        var member = this.state.member;        
        switch (t) {
            case 'name':
                member[index].name=e.target.value;
                break;
            case 'birthday':
                member[index].birthday=e.target.value;
                break;
            case 'id':
                member[index].id=e.target.value;
                break;
            default:
                console.log('error_handlerKeyChangeSub');
        }
        this.setState({member:member});
    }

    // 送出登記，登記資料只新增不更新(由旅宿做最後確認)
    handlerSubmit = async () =>{
        
        var setting={
            TableName:"fu_ta0001_checkin",
            Item:{                
                hotel_sn:this.state.hotelsn,    // 旅宿亂碼
                timestamp:new Date().getTime(),
                id:this.state.id.toUpperCase(),          // 使用者身份證字號
                firstdate:this.state.firstdate,   
                enddate:this.state.enddate,
                membercount:this.state.membercount,
                days:(new Date(this.state.enddate)- new Date(this.state.firstdate)) / 86400000,
                name:this.state.name,
                guestData:{},
                sn:sha256(this.state.hotelsn + '' + new Date())           // 訂單亂碼
            }
        };

        if (this.state.tel!==''){setting.Item.guestData.tel=this.state.tel;}

        if (this.state.birthday!==''){setting.Item.guestData.birthday=this.state.birthday;}

        if (this.state.email!==''){setting.Item.guestData.email=this.state.email;}

        if (await putData(setting)){this.setState({alertsubmit:'登記完成',guestsn:setting.Item.sn});}else{this.setState({alertsubmit:'登記未完成'});}
    }

    render() {       
        console.log(this.state.hotelcity);
        return(
         <div className="container-fluid">
            <div className="container "  style={{backgroundColor:'#AAFFEE'}}>
                <div className="row">
                    <div className="col-xs-12 col-md-12" style={{backgroundColor:'#AAFFEE'}}> 
                        <h1>{this.state.hotelcity===''?'':this.state.hotelname}</h1>
                        <h5>{this.state.hotelcity===''?'':this.state.hotelcity + this.state.hotelnum + "號"}</h5>
                        <h4>{this.state.hoteltel===''?'':'電話:' + this.state.hoteltel}</h4>
                        <h5>{this.state.hoteladdress===''?'':'住址:' + this.state.hoteladdress}</h5>
                        <h4>{this.state.hotelline===''?'':'lineID:' + this.state.hotelline}</h4>
                        <h4>{this.state.hotelwechat===''?'':'wechat:' + this.state.hotelwechat}</h4>

                        <h4>快速入住登記</h4>
                        <h6>*預填入住登記後，入住時只需核對證件即可，省時方便</h6>
                        <Form.Group controlId="formBasicfirstdate">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="firstdate">入住日期</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                type="date"
                                value={this.state.firstdate} 
                                placeholder="2020-01-01"
                                aria-label="2020-01-01"
                                aria-describedby="basic-addon1"
                                // onKeyDown={(e)=>{if (e.key === 'Enter'){this.handlerLogin()}}} 
                                onChange={e=>{this.handlerKeyChange(e,'firstdate')}}
                                />
                                {/* <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={()=>{this.handlerLogin()}}>登入</Button>
                                </InputGroup.Append> */}
                            </InputGroup>
                        
                        </Form.Group>

                        <Form.Group controlId="formBasicenddate">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="enddate">退房日期</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                type="date"
                                value={this.state.enddate} 
                                placeholder="2020-01-02"
                                aria-label="2020-01-02"
                                aria-describedby="basic-addon1"
                                // onKeyDown={(e)=>{if (e.key === 'Enter'){this.handlerLogin()}}} 
                                onChange={e=>{this.handlerKeyChange(e,'enddate')}}
                                />                                
                            </InputGroup>                        
                        </Form.Group>
                        
                        <Form.Group controlId="formBasictel">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="tel">聯絡電話</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                value={this.state.tel} 
                                placeholder="0900123456"
                                aria-label="0900123456"
                                aria-describedby="basic-addon1"
                                onChange={e=>{this.handlerKeyChange(e,'tel')}}
                                />
                            </InputGroup>                        
                        </Form.Group>

                        <Form.Group controlId="formBasicname">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="name">聯絡姓名</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                value={this.state.name} 
                                placeholder="請填入證件姓名"
                                aria-label="請填入證件姓名"
                                aria-describedby="basic-addon1"
                                onChange={e=>{this.handlerKeyChange(e,'name')}}
                                />
                            </InputGroup>                        
                        </Form.Group>
                        
                        <Form.Group controlId="formBasicbirthday">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="birthday">生日</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                type="date"
                                value={this.state.birthday} 
                                placeholder="2020-01-02"
                                aria-label="2020-01-02"
                                aria-describedby="basic-addon1"
                                onChange={e=>{this.handlerKeyChange(e,'birthday')}}
                                />
                            </InputGroup>                        
                        </Form.Group>

                        <Form.Group controlId="formBasicid">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="id">身份證字號</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                value={this.state.id} 
                                placeholder="A123456789"
                                aria-label="A123456789"
                                aria-describedby="basic-addon1"
                                onChange={e=>{this.handlerKeyChange(e,'id')}}
                                />
                            </InputGroup>
                        </Form.Group>
                        
                        <Form.Group controlId="formBasicemail">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="email">e-mail</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                type="email"
                                value={this.state.email} 
                                placeholder="email@gmail.com"
                                aria-label="email@gmail.com"
                                aria-describedby="basic-addon1"
                                onChange={e=>{this.handlerKeyChange(e,'email')}}
                                />
                            </InputGroup>                 
                            
                            <Form.Text className="text-muted">{this.state.alertemail}</Form.Text>       
                        </Form.Group>
                        
                        <Form.Group controlId="formBasicmembercount">
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="membercount">入住人數</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                value={this.state.membercount} 
                                placeholder="請填入住人數"
                                aria-label="請填入住人數"
                                aria-describedby="basic-addon1"
                                onChange={e=>{this.handlerKeyChange(e,'membercount')}}
                                />
                            </InputGroup>
                            <Form.Text className="text-muted">{this.state.alert}</Form.Text>
                        </Form.Group>
                    </div>
                </div>
            
                <div className="row">
                    {
                        this.state.member.map((element,index)=>      
                            <div className="col-xs-12 col-md-12" key={index}>
                            <div>第 {index+1} 位</div>
                            <Form.Group controlId={"formBasicname"+index}>
                                <InputGroup className="mt-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id={'name'+index}>姓名</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                    value={element.name} 
                                    placeholder="請填入姓名"
                                    aria-label="請填入姓名"
                                    aria-describedby="basic-addon1"
                                    onChange={e=>{this.handlerKeyChangeSub(e,index,'name')}}
                                    />
                                </InputGroup>                        
                            </Form.Group>
                                
                            <Form.Group controlId={"formBasicbirthday"+index}>
                                <InputGroup className="mt-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id={"birthday"+index}>生日</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                    value={element.birthday} 
                                    placeholder="2020-01-02"
                                    aria-label="2020-01-02"
                                    aria-describedby="basic-addon1"
                                    onChange={e=>{this.handlerKeyChangeSub(e,index,'birthday')}}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId={"formBasicid"+index}>
                                <InputGroup className="mt-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id={"id"+index}>身份證字號</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                    value={element.id} 
                                    placeholder="A123456789"
                                    aria-label="A123456789"
                                    aria-describedby="basic-addon1"
                                    onChange={e=>{this.handlerKeyChangeSub(e,index,'id')}}
                                    />
                                </InputGroup>
                            </Form.Group>
                            
                            </div>                         
                        )
                    }
                </div>
                
                <div className="row pb-3">
                    <div className="col-xs-12 col-md-12"> 
                        <Button
                        block
                        type="submit"
                        disabled={this.state.sumbitState}
                        onClick={()=>{this.handlerSubmit()}}
                        >
                        完成登記
                        </Button>

                    </div>
                </div>   
            </div>
         </div>   
        )
    }
}

