import React, { Component } from 'react';
import {Image, Badge, Carousel } from 'react-bootstrap';

import {BannerCarousel} from '../../Components/Attractions';

  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideList:[{src:'/banner-1.jpg', title:'金鐏漁港', memo:'海如絲、天如畫'},
            {src:'/banner-2.jpg', title:'伽路蘭休憩區', memo:'山海之間'},
            {src:'/banner-3.jpg', title:'舊鐵道步道', memo:'散散步，你會發現這有多好'},
            {src:'/banner-4.jpg', title:'炸寒單', memo:'元宵必看，熱鬧剌激'}
            ],
            NewAttList:[{src:'', title:'富里-稻草藝術節', memo:'稻草製作多樣特殊造型，大金剛、中國龍、龍貓、…等等，活動到3月底，值得一遊', link:'https://supertaste.tvbs.com.tw/travel/328647'},
            {src:'/banner-2.jpg', title:'池上-漂鳥197大地藝術季', memo:'最近台東最紅景點，多個搭配地景的裝置藝術，好看好拍好逛，有很多個，建議先查詢一下，決定要看那些再前往，因為太多了，要全看完會比較難一些', link:'https://www.pioneeringeastriftvalleygranaryfestivities.com.tw/?page_id=9647'},
            {src:'/banner-4.jpg', title:'關山-米國學校稻草迷宮', memo:'以稻草卷圍起來的迷宮，進去走走很好玩。旁邊還有一個高的觀景台，可以從上方往下指揮、拍照喔', link:'https://lanshih.com/taitung-ksfa-rice-school/'}
            ]
        }        
    }
    
    componentDidMount() {
        
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    render() {
        return(
         <div className="mx-auto container-fluid row">
            <div className="col-md-12 col-lg-9 p-0" >
                {/* <Image src={"/banner-1.jpg"} thumbnail width="100%" alt="首頁輪播" /> */}                
                <BannerCarousel interval='3000'></BannerCarousel>
            </div>
            <div className="col-md-12 col-lg-3 text-left pt-2" >
                <h3 className="col-md-12 col-sm-0 text-center"><u>近期推薦活動</u></h3>
                {
                    this.state.NewAttList.map((element,index)=>          
                        <div key={index} className="col-sm-12 col-md-6 col-lg-12 pb-2" >
                            <h5><b>{element.title}</b><small><a href={element.link} target='_blank' title='參考連結'><i className='bi-link'>連結</i></a></small></h5>
                            <small className="text-muted">{element.memo}</small>
                        </div>
                    )
                }
            </div>
            <div className="col-md-12 col-lg-12 row">
                <div className="col-lg-12">
                    <h5>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>美食</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>手作</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>慢活</Badge>
                    </h5>
                </div>
                {/* <div className='spinner-border'></div> */}
                <div className="col-lg-12 text-center"><h4 className="list-title">感覺。台東</h4>
                <p>
台東有壯闊的山與海，鮮豔明亮美麗</p>
                <p>
但是不走進來，看不到樹蔭下的自然</p>
                <p>
同樣的不走進來，看不到文化與傳承</p>
                <p>
這裡有那樣久的傳承，那麼多的文化</p>
                <p>
我們期盼讓更多人能接觸台東的美好</p>
                <p>
以最簡單、容易的方式去體會與感覺</p></div>
                {/* <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div> */}
            </div>
            
         </div>   
        )
    }
}

