import React, { Component } from 'react';
import {Image, Badge } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

import {nearAttData, LabelAttData, ListAttData} from '../../Components/Attractions';
// import googlemap from '../../Module/GoogleMap';

const GmapKey = 'AIzaSyDI8ew82mbkD6F0a3YcKKeol563aQtfnXY';

const AnyReactComponent = ({ title, text }) => <div style={{position: 'absolute',
  transform: 'translate(-51%, -20px)',textAlign: "center",width: 200}}><i className="fa fa-map-marker fa-2x" aria-hidden="true" title={title}></i><br/><h6 style={{color:'black',position: 'absolute',
  transform: 'translate(110px, -21px)',textAlign: "left"}}><b>{text}</b></h6><br/></div>;
// const AnyReactComponent1 = ({ text }) => <i className="fa fa-map-marker fa-2x" aria-hidden="true" style={{position: 'absolute',
//   transform: 'translate(-10%, -70%)',textAlign: "center"}}></i>;
// const AnyStateComponent = ({ text }) => <div style={{textAlign: "center",width: 100, height: 100}}><h5><b>{text}</b></h5></div>;

const DefaultMarker = ({ icon, text }) => (
  <div>
    <i className="fa fa-map-marker fa-2x" aria-hidden="true" style={{position: 'absolute',
  transform: 'translate(-10%, -70%)',textAlign: "center"}}></i>
    <div style={{width:"100px", textAlign:"left", paddingLeft:0, paddingTop:5}}>{text}</div>
  </div>
)

var gmap, gmaps;

  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapquery:'感覺台東民宿',
            setting:'',
            defCenter:{lat: 22.76716034048797, lng: 121.13002791587338},
            map:null,
            maps:null,
            mapload:false,
            zoom:20,
            selType:'',
            markers:[],
            bigImg:''
        }        
    }

    static defaultProps = {
        center: {lat:22.76716034048797, lng:121.13002791587338},
        zoom: 18 // 順便也放大一下縮放視角
    };
    
    componentDidMount() {
        if (this.props.name!==undefined){
            // this.setState({"mapquery":this.props.name,"setting":this.props.name});
        }
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    // 取得目前位置
    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.updateLocation);
        }
    }

    selectAttData = () =>{
    //   fetch("https://www.booking.com/hotel/tw/gan-jue-tai-dong-bnb.zh-tw.html#tab-reviews", {
    //     mode:'no-cors'
    //     method: "GET",
    //     headers: {
    //     "Content-Type": "application/json"
    //     })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
    //     },
    //     // Note: it's important to handle errors here
    //     // instead of a catch() block so that we don't swallow
    //     // exceptions from actual bugs in components.
    //     (error) => {
    //       console.log(error);
    //     }
    //   )

        var url = 'https://www.booking.com/hotel/tw/gan-jue-tai-dong-bnb.zh-tw.html#tab-reviews';
        // var xhr = new XMLHttpRequest()
        // xhr.open('GET', link, true)
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        // xhr.setRequestHeader('Content-Type', 'application/json');
        // xhr.addEventListener('load', () => {
        //     console.log(xhr);
        //     if (xhr && xhr.status === 200 && xhr.responseText){
        //         const dataset = JSON.parse(xhr.responseText).data;
        //         console.log(dataset);
        //         // this.setState({companies : dataset});
        //         // this.countRecord();
        //     }else{
        //         console.log(xhr);
        //     }
        // })
        // xhr.send();
        fetch(url, {
        mode:'no-cors',
        method: "GET"})
        .then((response) => {
            console.log(response);
        })
    }

    handleApiLoaded = (map, maps) =>{
        gmap=map;
        gmaps=maps;
        let marker = new gmaps.Marker({
            position: map.center,
            map:gmap,
            label: '感覺台東'
        });
        this.setState({mapload:true});
    }

    handleCenterChange = () =>{
        if (this.state.mapload){
            if (gmap.zoom>14){
                this.setState({zoom: gmap.zoom,defCenter:{lat:gmap.center.lat(), lng:gmap.center.lng()}});
            }else{
                this.setState({zoom: gmap.zoom});
            }
        }else{
            console.log('lord error!');
        }
    }

    handleMapClick = (mapCenter) =>{  // map 是點擊的資料
        // if (this.state.mapload){
            // console.log(mapCenter);
        console.log("lat:" + mapCenter.lat + ", lng:" + mapCenter.lng);
        
        for (let i = 0; i < this.state.markers.length; i++) {
            this.state.markers[i].setMap(null);
        }
        var markers=[];
        let marker = new gmaps.Marker({
        position: mapCenter,
        map:gmap,
        label:'指定位置',
        title: '指定位置'
        });
        markers.push(marker);        
        this.setState({markers:markers, zoom: gmap.zoom,defCenter:{lat:mapCenter.lat, lng:mapCenter.lng}});

        // }else{
        //     console.log('lord error!');
        // }
    }

    handlerByKeyword = (areaType)=>{
        if (this.state.selType!=areaType){
            this.setState({selType:areaType});
        }else{
            this.setState({selType:''});
        }
    }

    handlerImageZoom = (photoLink)=>{
        this.setState({bigImg:photoLink});
    }

    render() {

        var temAttData = nearAttData(this.state.defCenter, '1200');
        var AttData = ListAttData(temAttData,this.state.selType);

        return(
         <div className="mx-auto container-fluid row">
            <Image className="col-lg-9 col-md-12 img-fluid d-block mx-auto position-fixed fixed-top" src={this.state.bigImg} onClick={()=>this.handlerImageZoom('')} />
            {/* 說明 */}
            <div className="col-md-12 col-lg-12 center">
                <h3 className="p-1">地址：<u>台東縣台東市中興路二段122號</u></h3>
                <h3 className="p-1">
                　台九線/南迴公路終點/台東糖廠文創園區</h3>
                <h4 className="p-2">
                <mark>手機搜尋「感覺台東」即可導航</mark>
                </h4> 
            </div>

            {/* 地圖 */}
            <div className="col-md-12 col-lg-12 p-0" style={{height:"500px"}}>
                {/* <iframe width="100%" height="300" src={mapsrc} scrolling="no" frameborder="0" title='google map'></iframe> */}
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GmapKey }}　// API Key
                    defaultCenter={this.props.center} // 預設地圖視角，也就是我一打開會先看到哪個地區
                    defaultZoom={this.props.zoom} //　預設縮放視角
                    layerTypes={['TrafficLayer', 'TransitLayer']}   // 交通情況
                    // heatmapLibrary={false}  // 熱力圖:顯示該區域的密疏程度
                    // heatmap={{data}} //  熱力圖資料來源
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps)}  // map 指的是地圖物件，maps 指的是map api
                    onBoundsChange={()=>this.handleCenterChange()} // 移動地圖邊界時觸發 handleCenterChange
                    // onClick={(map)=>this.handleMapClick(map)}   // 點擊地圖
                >
                    {/* <DefaultMarker
                    lat={this.state.defCenter.lat} //　緯度
                    lng={this.state.defCenter.lng} // 經度
                    text="您的位置"　// 這個 Marker 的文字
                    /> */}
                    {/* <AnyReactComponent
                    lat={this.props.center.lat} //　緯度
                    lng={this.props.center.lng} // 經度
                    text="感覺台東民宿"　// 這個 Marker 的文字
                    /> */}
                    {
                        AttData.map((element,index)=>                                        
                            <AnyReactComponent
                            lat={element.latlng.lat} //　緯度
                            lng={element.latlng.lng} // 經度
                            text={element.areaName}　// 這個 Marker 的文字
                            title={element.notice}
                            key={index}
                            />                            
                        )
                    }
                </GoogleMapReact>
            </div>

            <div className="col-md-12 col-lg-12 row">
                {/* 標籤 */}
                <LabelAttData attData={temAttData} selType={this.state.selType} handlerByKeyword={this.handlerByKeyword}></LabelAttData>
                {/* <div className="col-lg-12">
                    <h5>
                    {                        
                        markertype.map((element,index)=>
                            <Badge className="m-1 pt-1" variant={this.state.selType===element?'success':'primary'} onClick={()=>{this.handlerByKeyword(element)}} style={{cursor: "pointer"}}>{element}</Badge>        
                        )                        
                    }
                    </h5>
                </div> */}

                <div className="col-md-12">
                    <h2><b>感覺台東民宿 鄰近景點/小吃/便利資訊 列表</b></h2>
                </div>
                
                {
                    AttData.map((element,index)=>                                        
                        <div className="mx-auto p-3 col-md-6 text-left left" key={'attData'+index}>
                            <h3 className="border-bottom border-primary mb-1"><b>{element.areaName}</b> <small>({element.dist}m)</small></h3>
                            <h5><Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword(element.areaType)}} style={{cursor: "pointer"}}>{element.areaType}</Badge></h5>
                            {/* <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                            <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge> */}
                            {element.photo === ''?'' : <Image className="img-fluid d-block" src={element.photo} onClick={()=>this.handlerImageZoom(element.photo)} />}
                            <h5 className="p-0">{element.Introduction}</h5>
                            {
                                element.notice ===''?'':<h5><mark>{element.notice}</mark></h5>
                            }
                            
                        </div>
                    )
                }    
            </div>    
         </div>   
        )
    }
}

