import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import sha256 from "sha256";
import moment from "moment";

import {queryBaseInfor} from './Module/AWS/DynamoDB/index';

import Navbar from './Components/Navbar';
import Footers from './Components/Footers';

import Introduction from './Components/Introduction';
import Booking from './Components/Booking';
import Travel from './Components/Travel';
import Taitung from './Components/Taitung';
import About from './Components/About';
import Room from './Components/Room';
import Map from './Components/Map';
import Template from './Components/Template';
import CheckIn from './Components/CheckIn';
import Register from './Components/Register';
// import Subscription from './Components/Subscription';
// import PhotoSet from './Components/PhotoSet';
// import NoMatch from './Components/NoMatch';

import './App.css';

const index = ()=>{
    return(
      <div><h1>感覺台東</h1></div>
    )
}

const NoMatch = ()=>{
    return(
      <div>
      <h3>查無頁面，請重新確認</h3>
      <h3><a href='/'>返回首頁</a></h3>
      </div>
      
    )
}

// const NoAuth = ()=>{
//     return(
//       <div>請登入後使用</div>
//     )
// }

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userBooking:false,
        userState:[],
        baseinfor:[],
        pages:[],
        sales:[]
    }
  }

  componentDidMount(){
    this.loadData();
  }
  
  loadData=async ()=>{
    var bInfor = await queryBaseInfor('futaitung');
    
    this.setState({baseinfor:JSON.parse(bInfor[0].baseinfor)[0],pages:JSON.parse(bInfor[0].pages),sales:JSON.parse(bInfor[0].sales)});


  // const history = browserHistory();
    console.log(document.referrer); // 轉址前網址
    var userState={ref:document.referrer, url:document.URL, booking:false};
    if (userState.url.indexOf('Booking')>-1){
      userState.booking=true;
      var tmp_params=decodeURIComponent(userState.url.substr(userState.url.indexOf('Booking/')+8)).split('/');

      tmp_params[1]=moment(tmp_params[1].substr(0,tmp_params[1].indexOf('（')).replace('年',"/").replace('月',"/").replace('日',"")).format("Y-M-D");
      tmp_params[3]=parseInt(tmp_params[3].replace(",","").replace("TWD ",""));
      this.setState({userState:tmp_params});

    }
    console.log(userState); // 轉址前網址
  }

  handlePhotoSet=(set)=>{
    // console.log(set);
    this.setState({set:set});
    localStorage.setItem('set',JSON.stringify(set));
  }

  handleloginPhone=(loginPhone)=>{
    this.setState({loginPhone:loginPhone,set:JSON.parse(localStorage.getItem('set'))});
    localStorage.setItem('loginPhone',loginPhone);    
  }

  handlePhotoSets=(photoSets)=>{
    localStorage.setItem('photoSet',photoSets);    
  }

  handlelogout=()=>{
    this.setState({loginPhone:null,set:JSON.parse(localStorage.getItem('set'))});
    localStorage.removeItem('loginPhone');
    // localStorage.removeItem('set');
    localStorage.clear();
    window.location.replace('/subscription');
  }

  render() {
    // console.log(useParams());
      return (
        <Router>
        <div className="App">
          
            {/* Navbar */}
          <Navbar pages={this.state.pages} baseinfor={this.state.baseinfor} />
          <div style={{height:'44px'}} />

          <Switch>
            {/* 根目錄 */}
            <Route path="/"　exact render={()=><Taitung />} />
            <Route path="/Taitung" render={props => <Taitung />}/>
            
            {/* 行程介紹 */}
            <Route path="/Travel" render={props => <Travel />}/>

            {/* 景點導覽 */}
            {/* 景點介紹 */}
            <Route path="/Introduction/:name" render={props => <Introduction name={props.match.params.name} />}/>
            {/* 景點導覽列表 */}
            <Route path="/Introduction" render={props => <Introduction />}/>
            

            {/* 房型介紹 */}
            <Route path="/Room" render={props => <Room />}/>

            {/* 尋找感覺 */}
            <Route path="/Map" render={props => <Map />}/>

            {/* 尋找感覺 */}
            <Route path="/About" render={props => <About />}/>

            {/* 模版頁面 */}
            <Route path="/news/:index" render={props => <Template />}/>

            {/* 訂房資訊 */}
            <Route path="/Booking/:name/:date/:night/:price" render={props => <Booking urlParams={props.match.params} />}/>

            {/* 入住瀏覽 */}
            <Route path="/checkin/:hotel/:id" component={props => <Register hotel={props.match.params.hotel} />}/>
            <Route path="/checkin/:hotel" render={props => <CheckIn hotel={props.match.params.hotel} />}/>
            
            {/* <Route path="/Subscription" render={props => <Subscription set={this.state.set} login={this.handleloginPhone} />}/> */}
{/*             
            {localStorage.getItem('loginPhone')===null?
            <Route component={NoAuth} />:
              <Route path="/Upload" render={props => <Upload set={this.state.set} />}/>            
            } */}
            <Route component={NoMatch} />
          </Switch>
          {/* <Route path="/"></Route>
          
          <Route path="/photoSet"></Route>

          
          <Route path="/subscription"></Route> */}
          <Footers baseinfor={this.state.baseinfor} /> 
        </div>
        </Router>
      )
  }
}

export default App;
