import AWS from 'aws-sdk';


// ======================AWS config======================
const awsconfig = {
    // bucketName: 'website',
    // dirName: 'photos',
    region: 'us-east-1',
    accessKeyId: 'AKIAV664B5Q4F647JUOB',
    secretAccessKey: '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'
    // s3Url: 'https://lifephoto.s3-ap-northeast-1.amazonaws.com',
    // endpoint:"https://dynamodb.ap-northeast-1.amazonaws.com/"
}


// ======================dynamodb config======================
AWS.config.update(awsconfig);

export const docClient = new AWS.DynamoDB.DocumentClient();
// const TableName = ['lifephoto','']

// const queryParams = {
//     TableName : "lifephoto",
//     KeyConditionExpression: "",
//     ExpressionAttributeNames:{},
//     ExpressionAttributeValues:{}
// }

// const putItemParams = {
//     TableName : "lifephoto"
// }

// const updateParams = {
//     TableName : "lifephoto"
// }

// const deleteParams = {
//     TableName : "lifephoto"
// }


export const dbPutItem= (TableName,params) =>{
    if (TableName===''){return false;}
    var putItemP= {};
    putItemP.TableName=TableName;
    putItemP.Item=params;
    docClient.put(putItemP, function(err, data){
        if (err){
            console.error("unable to query", JSON.stringify(err, null,2));
        }else{
            console.log("query succeeded", JSON.stringify(data));
        }
    })
}


// ================keywords upload====================
export const newKeywords = (keyword, count) =>{    
    if (keyword!==null){
        var params={
            TableName:"lifephoto_keywords",
            Item:{
                groupid:JSON.parse(localStorage.getItem('set')).id,
                timestamp:new Date().getTime(),
                keywords:keyword,
                count:count
            }
        };

        docClient.put(params, function(err, data){
            if (err){
                console.error(err);
            }else{
                // console.log(data);
                return true;
            }
        })
    }
}

export const addKeywordsCount = (ts,keyword,count) =>{    
    if (keyword!==null){
        count=count===''?1:count;
        var params={
            TableName:"lifephoto_keywords",
            Key:{
                groupid:JSON.parse(localStorage.getItem('set')).id,
                timestamp:ts
            },
            UpdateExpression:"set #count = #count + :count",
            ConditionExpression:"keywords = :keywords",
            ExpressionAttributeNames:{"#count": "count"},
            ExpressionAttributeValues:{":count": count, ":keywords" : keyword},
            ReturnValues:"UPDATED_NEW"
        };

        docClient.update(params, function(err, data){
            if (err){
                console.error(err);
            }else{
                console.log(data);
                return true;
            }
        })
    }
}

// ================photo upload====================
export const photoUpload = (link,keyword,user) =>{    
    if (link!==null && user!==null){
        var params={
            TableName:"lifephoto_photo",
            Item:{
                groupid:JSON.parse(localStorage.getItem('set')).id,
                timestamp:new Date().getTime(),
                pid:JSON.parse(localStorage.getItem('set')).id+'_'+String(new Date().getTime()),
                link:link,
                keywords:keyword,
                uploadUser:user
            }
        };
        docClient.put(params, function(err, data){
            if (err){
                console.error(err);
            }else{
                // console.log(data);
                return true;
            }
        })
    }
}


// ================user upload====================
export const updateUserps = (phone,password) =>{    
    if (phone!==null && password!==null){
        var params={
            TableName:"lifephoto_user",
            Key:{
                phone:phone
            },
            UpdateExpression:"set pw = :pw",
            ExpressionAttributeValues:{":pw":password},
            ReturnValues:"UPDATED_NEW"
        };

        docClient.update(params, function(err, data){
            if (err){
                console.error(err);
            }else{
                console.log(data);
                return true;
            }
        })
    }
}

// ===================人員登入==========================
export async function loginUser(loginPhone) {
    return new Promise(function(resolve, reject) {
    try {            
        var params={
            ExpressionAttributeValues: {    // 參數值
                ':phone': loginPhone
            },
            ExpressionAttributeNames:{"#name" : "name", "#groupid" : "groupid"},  // 參數名
            KeyConditionExpression: 'phone = :phone',  // 關鍵字
            ProjectionExpression:'#name, #groupid',    // 欄位
            ScanIndexForward:false,  // 排序
            Limit:1,    // 回傳數量
            TableName: 'lifephoto_user'    // 資料表
        };
        
        docClient.query(params, function(err,data){
            if (err){
                console.error(err);
                resolve(null);
            }else{
                // console.log(data);   
                resolve(data.Items);   
            }
        })        
    } catch (error) {
        console.log(error);
    }
    });
}


// ===================關鍵字列表==========================
export async function queryKeyWords(groupid) {
    return new Promise(function(resolve, reject) {
    try {            
        if (groupid===null){resolve([]);return false;}
        var params={
            KeyConditionExpression: 'groupid = :groupid',  // 條件式
            ProjectionExpression:'#count, #keywords',    // 回傳欄位
            ExpressionAttributeNames:{"#count" : "count", "#keywords" : "keywords"},  // 參數名
            ExpressionAttributeValues: {    // 參數值
                ':groupid': groupid
            },
            // IndexName:"keywords",
            ScanIndexForward:false,  // 排序
            // Limit:1,    // 回傳數量
            TableName: 'lifephoto_keywords'    // 資料表
        };
        
        docClient.query(params, function(err,data){
            if (err){
                console.error(err);
                resolve([]);
            }else{
                console.log(data.Items);   
                resolve(data.Items);   
            }
        })        
    } catch (error) {
        console.log(error);
    }
    });
}


// ===================照片列表==========================
export async function queryPhotos(groupid) {
    return new Promise(function(resolve, reject) {
    try {          
        if (groupid===null){resolve([]);return false;}
        var params={
            ExpressionAttributeValues: {    // 參數值
                ':gid': groupid
                // ':ts' : {N: 1582299115240}
            },
            // ExpressionAttributeNames:{"#timestamp" : "timestamp"},  // 參數名
            KeyConditionExpression: 'groupid = :gid',  // 關鍵字
            // ProjectionExpression:'',    // 欄位
            ScanIndexForward:false,  // 排序
            // Limit:10000,    // 回傳數量
            TableName: 'lifephoto_photo'    // 資料表
        };
        
        docClient.query(params, function(err,data){
            if (err){
                console.error(err);
                resolve([]);
            }else{
                console.log(data.Items);   
                resolve(data.Items);
            }
        })        
    } catch (error) {
        console.log(error);
    }
    });
}

// ===================基本資料==========================
export async function queryBaseInfor(param) {
    return new Promise(function(resolve, reject) {
    try {
        if (param ===null || param===""){resolve(null);}

        var params={
            FilterExpression: "#name = :name",
            ProjectionExpression:'baseinfor, pages, sales',    // 欄位
            ExpressionAttributeValues: {":name" : param},   //  參數資數
            ExpressionAttributeNames:{"#name" : "name"},  // 參數名

            ScanIndexForward:false,  // 排序
            Limit:1,    // 回傳數量
            TableName: 'website'    // 資料表
        };
        
        // console.log(params);

        docClient.scan(params, function(err,data){
            if (err){
                console.error(err);
                resolve(null);
            }else{
                // console.log(data.Items);   
                resolve(data.Items);
            }
        })        
    } catch (error) {
        console.log(error);
    }
    });
}

// ===================旅宿資料==========================
export async function queryhoteldata(param) {
    return new Promise(function(resolve, reject) {
    try {
        if (param ===null || param===""){resolve(null);}

        var params={
            FilterExpression: "name = :name",
            ProjectionExpression:'name, password, baseinfor, pages, sale',    // 欄位
            ExpressionAttributeValues: {":name" : param},
            // ExpressionAttributeNames:{"#name" : "name"},  // 參數名

            ScanIndexForward:false,  // 排序
            Limit:1,    // 回傳數量
            TableName: 'website'    // 資料表
        };
        
        console.log(params);

        docClient.scan(params, function(err,data){
            if (err){
                console.error(err);
                resolve(null);
            }else{
                // console.log(data.Items);   
                resolve(data.Items);
            }
        })        
    } catch (error) {
        console.log(error);
    }
    });
}


// ===================新增資料==========================
export async function putData(params) {
    return new Promise(function(resolve, reject) {
    try {
        if (params ===null || params===""){resolve(false);}

        console.log(params);

        // params.ReturnValues="UPDATED_NEW";

        docClient.put(params, function(err, data){
            if (err){
                console.error(err);
                resolve(false);
            }else{
                console.log(data);
                resolve(true);
            }
        })     
    } catch (error) {
        console.log(error);
    }
    });
}















// export const queryData= async (TableName,params)=>{
//     if (TableName !== null){
//         params.TableName='lifephoto';
//         // params.Key={pid:"test_1582202143704", timestamp:"1582202143704"};
//         params.FilterExpression = "begins_with(pid,:pid)";                // 搜尋條件
//         // params.ExpressionAttributeNames={"#timestamp":"timestamp"};         //參數名稱
//         params.ExpressionAttributeValues={":pid":JSON.parse(localStorage.getItem('set')).id + "_"};    //參數值
        
//         console.log(params);
//         var test = await docClient.scan(params,await function(err,data){
//             if (err){
//                 console.log(params);
//                 console.error(err);
//             }else{
//                 console.log(data);
//                 return true;
//             }
//         });
//         console.log(test.response);
//     }
// }

// export const deleteData=(TableName,params)=>{
//     if (TableName !== null){
//         params.TableName='lifephoto';
//         params.Key={pid:"test_1582202143704", timestamp:"1582202143704"};
//         params.ConditionExpression= "contains (pid,:pid)";                // 搜尋條件
//         // params.ExpressionAttributeNames={"#timestamp":"timestamp"};         //參數名稱
//         params.ExpressionAttributeValues={":pid":{S:"158"}};    //參數值
        
//         console.log(params);
//         docClient.delete(params, function(err, data) {
//             if (err) {
//             console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null,
//             2));
//             } else {
//             console.log("DeleteItem succeeded:", JSON.stringify(data, null, 2));
//             }
//         });
//     }
// }
