import React, { Component } from 'react';
import {Image, Badge, Carousel } from 'react-bootstrap';

  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlParams:this.props.urlParams
        }        
    }
    
    componentDidMount() {
        
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        console.log(nextProps);
    }

    render() {
        console.log(this.state.urlParams);
        return(
         <div className="mx-auto container-fluid row">
            <div className="mx-auto p-3 col-12 text-center">
                <h3 className="border-bottom border-primary mb-2"><b>{this.state.urlParams.name} 
            您好</b></h3>                    
                <h5>您預約的是 {this.state.urlParams.date} 入住感覺台東民宿 { this.state.urlParams.night} 晚</h5>
            </div>
            
            <div className="mx-auto p-3 col-12 text-center">
                <h3 className="border-bottom border-primary mb-2"><b>住宿說明</b></h3>                    
                <h5>歡迎您到台東走走，感覺台東民宿以服務為主，包含住宿需求到旅遊資訊、景點推薦、行程安排都可以與我們聯絡喔，我們誠心希望每一位旅客都能充份的了解台東、感覺台東，並能留下美好的回憶</h5>
                <h5></h5>
                <h5></h5>
                <h5></h5>
            </div>
         </div>   
        )
    }
}

