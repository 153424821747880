import React, { Component } from 'react';
import { Navbar, Nav, Form } from 'react-bootstrap';


  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // baseinfor:[]
        }        
    }
    
    componentDidMount() {

    }
    
    componentWillUnmount() {}


    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // this.setState({baseinfor:nextProps.baseinfor});
    }

    render() {
        return(
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-4 col-md-1 align-self-center"> <a href={this.props.baseinfor.fb} target="blank" title='Facebook粉專'>
                        <i className="fa fa-fw fa-facebook-official text-muted fa-2x"></i>
                    </a> </div>
                    <div className="col-4 col-md-1 align-self-center"> <a href={this.props.baseinfor.line} target="blank" title='加入Line@'>
                        <i className="fa fa-fw fa-comments text-muted fa-2x"></i>
                        {/* <i className="fas fa-search-location fa-2x"></i><i className="fas fa-map-marker-alt"></i> */}
                    </a> </div>
                    <div className="col-4 col-md-1 align-self-center"> <a href={this.props.baseinfor.ig} target="blank" title='IG Link'>
                        <i className="fa fa-fw fa-instagram text-muted fa-2x"></i>
                        {/* <i className="fas fa-search-location fa-2x"></i><i className="fas fa-map-marker-alt"></i> */}
                    </a> </div>
                    <div className="col-sm-0 col-md-6 text-center pt-4">
                        <h6>{this.props.baseinfor.mobilenumber}</h6>                        
                    </div>
                    <div className="col-12 col-md-3 text-center">
{/*                         
                        <form className="form-inline">
                            <div className="form-group">輸入電子信箱接收最新台東旅遊資訊 <input type="email" className="form-control" placeholder="Your e-mail here"></input> </div> <button type="submit" className="btn btn-primary ml-3">訂閱</button>
                        </form> */}
                        <div className="m-4">
                            <p>Copyright© 2021 感覺台東</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
