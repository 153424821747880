import React, { Component } from 'react';
import {Image, Carousel } from 'react-bootstrap';


  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideList:[{src:'/banner-1.jpg', title:'金鐏漁港', memo:'海如絲、天如畫'},
            {src:'/banner-2.jpg', title:'伽路蘭休憩區', memo:'山海之間'},
            {src:'/banner-3.jpg', title:'舊鐵道步道', memo:'散散步，你會發現這有多好'},
            {src:'/banner-4.jpg', title:'炸寒單', memo:'元宵必看，熱鬧剌激'}
            ]
        }        
    }
    
    componentDidMount() {
        
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    render() {        
      const style_Caption = {
        right: '2%',
        bottom: '1%',
        // color:'black',
        textAlign: 'right',
        // maxWidth: '300px',
        left: 'auto',
        padding:'1px'
    }    
        return(
         <div className="mx-auto container-fluid row">
            <div className="col-md-12 col-lg-9 p-0" >
                {/* <Image src={"/banner-1.jpg"} thumbnail width="100%" alt="首頁輪播" /> */}
                
                <Carousel interval="3000">
                {   
                    this.state.slideList.map((element,index)=>
                        <Carousel.Item key={index}>
                            <img
                            className="d-block w-100"
                            src={element.src}
                            alt="slide"
                            />
                            <Carousel.Caption style={style_Caption}>
                            <h3>{element.title}</h3>
                            <p>{element.memo}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                }                
                </Carousel>
            </div>
            {/* <div className="col-md-12 col-lg-3 row text-left pt-2" >
                <h3 className="col-md-12 col-sm-0 text-center"><u>近期推薦活動</u></h3>
                {
                    this.state.NewAttList.map((element,index)=>          
                        <div className="col-sm-12 col-md-6 col-lg-12 pb-2" >
                            <h5><b>{element.title}</b><small><a href={element.link} target='_blank' title='參考連結'><i className='bi-link'>連結</i></a></small></h5>
                            <small className="text-muted">{element.memo}</small>
                        </div>
                    )
                }
            </div> 
            <div className="col-md-12 col-lg-12 row">
                <div className="col-lg-12">
                    <h5>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>美食</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>手作</Badge>
                        <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>慢活</Badge>
                    </h5>
                </div>*/}
                {/* <div className='spinner-border'></div> */}
                <div className="mx-auto p-3 col-12 text-left">
                    <h2 className="mb-1"><b>關於感覺</b></h2>
                    
                    <p>感覺台東是希望每一位旅客都能好好的體驗台東的美好，美麗的風景、自然的環境、舒服的空氣都是值得您來走走的。</p>
                    <p>我們提供的不只是乾淨舒服的住宿，也包含了台東大大小小資訊的提供。有著名小吃、特色料理、網美景點、當季活動、DIY手作、套裝行程、…等等，景點小吃問路任何事都可以與我們聯絡(Line連結：http://line.me/ti/p/%40arw4940w），加Line還會有景點地圖與美食地圖可供參考喔</p>
                    <p></p>
                    <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                
                
                {/* <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div>
                <div className="mx-auto p-3 col-md-6 text-left">
                <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                <Image className="img-fluid d-block" src="/banner-1.jpg" />
                </div> */}
            
        </div>   
        )
    }
}

