import React, { Component } from 'react';
import {Image, Badge, Carousel } from 'react-bootstrap';


  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photosize:['100px','100px'],
            photointerval:'5000',
            photolist:[{link:'/banner-1.jpg',title:'金鐏漁港',memo:'海如絲、天如畫'},{link:'/banner-2.jpg',title:'伽路蘭休憩區',memo:'山海之間'},{link:'/banner-3.jpg',title:'舊鐵道步道',memo:'散散步，你會發現這有多好'}],
            rooms:[{name:'雙人房',photos:['https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6349_FullSize.jpg','https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6297_FullSize.jpg','https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6350_FullSize.jpg']},{name:'四人房',photos:['https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6370_FullSize.jpg','https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6385_FullSize.jpg']},{name:'全棟',photos:['https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6349_FullSize.jpg','https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6370_FullSize.jpg','https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6446_FullSize.jpg','https://dchlu2uso6pre.cloudfront.net/Room/20151229_IMG_6408_FullSize.jpg']}]
        }        
    }
    
    componentDidMount() {
        
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    render() {        
        const style_Caption = {
            right: '2%',
            bottom: '1%',
            // color:'black',
            textAlign: 'right',
            // maxWidth: '300px',
            left: 'auto',
            padding:'1px'
        }    
        return(
         <div className="mx-auto container-fluid row">
            <div className="col-md-12 p-0" >
                <h3 className="border-bottom border-primary pb-3">房型介紹</h3>
                <h5>每間房型都配有電視MOD/WIFI/100M寬頻/沙發/小餐桌/梳妝台/小冰箱/冷氣/獨立浴室/乾溼分離/水力足/儲熱式熱水/德泰床墊。</h5>
                <h5>盥洗用品包含洗髮乳/沐浴乳/肥皂/刮鬆刀/浴帽/梳子/牙刷/紙杯/大毛巾/瓶裝水</h5>
                <h5>二樓與三樓各有一間雙人房與四人房，如有特別需求，預訂時請說明需要之樓層</h5>
                <h5>如有抽菸需要，建議安排三樓，因為四樓頂樓是空曠陽台，上樓即可，較為方便。</h5>
                <h5><mark>民宿內部全區禁止抽菸喔</mark></h5>
            </div>
            <div className="col-md-12 col-lg-12 row m-0 pt-3 p-0">
                {/* <div className="col-lg-12">
                <Badge className="m-1 pt-1" variant="primary">美食</Badge>
                <Badge className="m-1 pt-1" variant="primary">景點</Badge>
                <Badge className="m-1 pt-1" variant="primary">親子</Badge>
                <Badge className="m-1 pt-1" variant="primary">放風</Badge>
                <Badge className="m-1 pt-1" variant="primary">手作</Badge>
                <Badge className="m-1 pt-1" variant="primary">慢活</Badge>
                </div> */}
                <div className="mx-auto p-3 col-md-12 col-lg-6 text-left" style={{maxheight:'200px'}}>
                    <h5 className='border-bottom border-primary'><b>{this.state.rooms[0].name}　</b><small>平日1500/假日1800/原價3000</small></h5>
                    <div className="col-md-12 p-0">
                        <Carousel interval={this.state.photointerval}>                    
                            {
                                this.state.rooms[0].photos.map((element, index) =>                                 
                                    <Carousel.Item key={index}>
                                        <img
                                        className="d-block w-100 mh-100"
                                        src={element}
                                        alt="slide"
                                        />
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </div>
                    <h5>
                    <Badge className="m-1 pt-1" variant="primary">窗景</Badge>
                    <Badge className="m-1 pt-1" variant="primary">沙發</Badge>
                    <Badge className="m-1 pt-1" variant="primary">小餐桌</Badge>
                    <Badge className="m-1 pt-1" variant="primary">梳妝台</Badge>
                    </h5>
                    <p>標準雙人床，寬敞明亮的空間，配有沙發與小餐桌；獨立使用的浴室，水力足，洗去一天的疲憊</p>
                </div>
                <div className="mx-auto p-3 col-md-12 col-lg-6 text-left">
                    <h5 className='border-bottom border-primary'><b>四人房　</b><small>平日2400/假日2800/原價4000</small></h5>                
                    <div className="col-md-12 p-0" >
                        <Carousel interval={this.state.photointerval}>                    
                            {
                                this.state.rooms[1].photos.map((element, index) =>                                 
                                    <Carousel.Item key={index}>
                                        <img
                                        className="d-block w-100 mh-100"
                                        src={element}
                                        alt="slide"
                                        />
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </div>
                    <h5>
                    <Badge className="m-1 pt-1" variant="primary">安靜</Badge>
                    <Badge className="m-1 pt-1" variant="primary">幼童佈置</Badge>
                    <Badge className="m-1 pt-1" variant="primary">梳妝台</Badge>
                    </h5>
                    <p>二張標準雙人床，寬敞明亮的空間，配有沙發與小餐桌；獨立使用的浴室，水力足，洗去一天的疲憊;可將床靠牆，幼童睡眠更安心</p>
                </div>
                <div className="mx-auto p-3 col-md-12 col-lg-6 text-left">
                    <h5 className='border-bottom border-primary'><b>全棟　</b><small>平日7000/假日9000/原價14000</small></h5>                
                    <div className="col-md-12 p-0" >
                        <Carousel interval={this.state.photointerval}>                    
                            {
                                this.state.rooms[2].photos.map((element, index) =>                                 
                                    <Carousel.Item key={index}>
                                        <img
                                        className="d-block w-100 mh-100"
                                        src={element}
                                        alt="slide"
                                        />
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </div>
                    <h5>
                    <Badge className="m-1 pt-1" variant="primary">專屬空間</Badge>
                    <Badge className="m-1 pt-1" variant="primary">不被打擾</Badge>
                    <Badge className="m-1 pt-1" variant="primary">團體出遊</Badge>
                    </h5>
                    <p>共有二間雙人房、二間四人房，有6張雙人床，最多可容納12位旅客</p>
                    
                </div>
                <div className="p-3 col-md-12 col-lg-6 text-left">
                    <h5 className='border-bottom border-primary'>特約資訊</h5>
                    <h6>舊客優惠</h6>
                    <h6>台東糖廠文創園區商家</h6>
                    <h6>寬仕工業股份有限公司</h6>
                    <h6>臺東縣私立均一實驗高級中學</h6>
                    <h6>布拉瑞揚舞團</h6>
                    <h6>阿水工坊</h6>
                    <h6>卡塔文化工作坊</h6>
                    <h6>台灣身心教育學會</h6>                    
                </div>
                <div className="p-3 col-md-12 col-lg-6 text-left">
                    <h5 className='border-bottom border-primary'>訂房資訊</h5>                    
                    <h6>為了提供各位更優質的住房品質，有些事項希望大家和一起配合，謝謝！</h6>
                    <h6>房間內禁止吸煙、攜帶寵物</h6>
                    <h6>訂房後需於三天內支付訂金，逾期後會開放給候補的客人預訂</h6>
                    <h6>為了不影響其他客人的住宿品質，晚上十一點以後請放輕音量</h6>
                    <h6>　 </h6>
                    <h6>匯款銀行：第一銀行 台東分行(007)</h6>
                    <h6>戶　　名：張家瑋</h6>
                    <h6>帳　　號：811-503-27131</h6>
                    <h6>　 </h6>
                    <h6>點選底部Line圖示可加Line聯絡訂房相關事宜 ^_^</h6>
                </div>
            </div>
         </div>   
        )
    }
}

