import React, { Component } from 'react';
import { Navbar, Nav, Form, Breadcrumb } from 'react-bootstrap';
import { Link } from "react-router-dom";


  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // baseinfor:[],
            // pages:[]
        }        
    }
    
    componentDidMount() {

    }
    
    componentWillUnmount() {}


    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps);
        // this.setState({baseinfor:nextProps.baseinfor,pages:nextProps.pages});
    }

    render() {       
        const Navbartop = {
            position: 'fixed',
            width:'100%',
            zIndex:'999',
            backgroundColor:'lightblue',
            height:'40px'
        }    
        return(            
            <Navbar className="navbar pt-0 pb-0 fixed-top" collapseOnSelect expand="sm" bg="light" variant="light" >
                <Navbar.Brand  style={{minHeight:'44px'}} href="/" to="/" className="navbar-brand pl-2"><h3 className="m-0"><b>{this.props.baseinfor.name}</b></h3></Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler navbar-toggler-center border-0" />
                <Navbar.Collapse className="collapse navbar-collapse" id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        {
                            this.props.pages.map((element,index)=>    
                                <Link className="NavLink" key={index} to={"/"+element.component} >{element.page}</Link>
                            )
                        }
                        {/* <Nav.Link href="/" >近期活動</Nav.Link> */}
                        {/* <Nav.Link href="/Travel" >行程介紹</Nav.Link> */}
                        {/* <Nav.Link href="/Introduction" >景點導覽</Nav.Link> */}
                        {/* <Nav.Link href="/Room" >房型介紹</Nav.Link>
                        <Nav.Link href="/Map" >尋找感覺</Nav.Link>
                        <Nav.Link href="/About" >關於感覺</Nav.Link> */}
                        {/* <Form className="ml-auto pt-2">
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="定位"
                            />
                        </Form> */}
                    </ Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
