import React, { Component } from 'react';
import {Image, Badge } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';

const GmapKey = 'AIzaSyDI8ew82mbkD6F0a3YcKKeol563aQtfnXY';

const AnyReactComponent = ({ text }) => <div style={{position: 'absolute',
  transform: 'translate(-50%, -50%)',textAlign: "center"}}><i className="fa fa-map-marker fa-2x" aria-hidden="true"></i><br/><h6><small>{text}</small></h6><br/></div>;
const AnyReactComponent1 = ({ text }) => <i className="fa fa-map-marker fa-2x" aria-hidden="true" style={{position: 'absolute',
  transform: 'translate(-10%, -70%)',textAlign: "center"}}></i>;
const AnyStateComponent = ({ text }) => <div style={{textAlign: "center",width: 100, height: 100}}><h5><b>{text}</b></h5></div>;

const DefaultMarker = ({ icon, text }) => (
  <div>
    <i className="fa fa-map-marker fa-2x" aria-hidden="true" style={{position: 'absolute',
  transform: 'translate(-10%, -70%)',textAlign: "center"}}></i>
    <div style={{width:"100px", textAlign:"left", paddingLeft:0, paddingTop:5}}>{text}</div>
  </div>
)




const stateData = [{areaName:'卑南豬血湯',latlng:{lat:22.77813181282595, lng:121.12582850578852},areaType:'著名小吃', areaDistance:0, Introduction:'濃郁湯頭、Q彈豬血，搭配小米腸，滿足好吃。', notice:'停車不方便，旁邊巷子裡有專用停車場喔'},
{areaName:'好漁日鬼頭刀專賣',latlng:{lat:22.772255025017067, lng:121.13184375543722},areaType:'著名小吃', areaDistance:0, Introduction:'專門以鬼頭刀料理店，店內乾淨明亮，店內還有很多不錯的農特產可以選購', notice:''}
]

var gmap, gmaps;

  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapquery:'感覺台東民宿',
            setting:'',
            defCenter:{lat: 22.7675, lng: 121.1290},
            map:null,
            maps:null,
            mapload:false,
            zoom:14
        }        
    }

    static defaultProps = {
        center: {lat:22.76759576268918, lng:121.12912678792485},
        zoom: 14 // 順便也放大一下縮放視角
    };
    
    componentDidMount() {
        if (this.props.name!==undefined){
            this.setState({"mapquery":this.props.name,"setting":this.props.name});
        }
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps);
    }

    // 取得目前位置
    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.updateLocation);
        }
    }

    handleApiLoaded = (map, maps) =>{
        gmap=map;
        gmaps=maps;
        let marker = new gmaps.Marker({
            position: map.center,
            map:gmap,
            text: 'Hello World!'
        });
        this.setState({mapload:true});
    }

    handleCenterChange = (map) =>{
        if (this.state.mapload){
            // console.log('bind success');

            console.log(gmap.zoom);

            this.setState({defCenter:{lat:map.lat, lng:map.lng}});
        }else{
            console.log('lord error!');
        }
    }

    onZoomChanged = (map) => {
        console.log(map);
        this.setState({
            zoom: this.googleMap.current.getZoom()
        });
    };

    handleMapClick = (mapCenter) =>{  // map 是點擊的資料
        // if (this.state.mapload){
            // console.log(mapCenter);
            console.log(gmap);
            console.log(gmaps);
            // const _maps = this.state.maps;
            // var gmap = this.state.map;            
            // var position= new gmaps.Position({lat:mapCenter.lat,lng:mapCenter.lng});
            let marker = new gmaps.Marker({
                // position: position,
                gmap,
                text: 'Hello World!'
            });
            console.log("lat:" + mapCenter.lat + ", lng:" + mapCenter.lng);
        // }else{
        //     console.log('lord error!');
        // }
    }
    render() {
        // const mapsrc="https://maps.google.com/maps?q=" + this.state.mapquery + "&ie=UTF8&z=14&iwloc=B&output=embed";
        console.log(this.props.name);
        return(
         <div className="mx-auto container-fluid row">
            <div className="col-md-12 col-lg-9 p-0" style={{height:"300px"}}>
                {/* <iframe width="100%" height="300" src={mapsrc} scrolling="no" frameborder="0" title='google map'></iframe> */}
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GmapKey }}　// API Key
                    defaultCenter={this.props.center} // 預設地圖視角，也就是我一打開會先看到哪個地區
                    defaultZoom={this.props.zoom} //　預設縮放視角
                    layerTypes={['TrafficLayer', 'TransitLayer']}   // 交通情況
                    // heatmapLibrary={false}  // 熱力圖:顯示該區域的密疏程度
                    // heatmap={{data}} //  熱力圖資料來源
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps)}  // map 指的是地圖物件，maps 指的是map api
                    onBoundsChange={({map, maps})=>this.handleCenterChange(map)} // 移動地圖邊界時觸發 handleCenterChange
                    onClick={(map)=>this.handleMapClick(map)}   // 點擊地圖
                >
                    {/* <DefaultMarker
                    lat={this.state.defCenter.lat} //　緯度
                    lng={this.state.defCenter.lng} // 經度
                    text="您的位置"　// 這個 Marker 的文字
                    /> */}
                     <DefaultMarker
                    lat={this.props.center.lat} //　緯度
                    lng={this.props.center.lng} // 經度
                    text="感覺台東民宿"　// 這個 Marker 的文字
                    />
                    {
                        stateData.map((element,index)=>                                        
                            <DefaultMarker
                            lat={element.latlng.lat} //　緯度
                            lng={element.latlng.lng} // 經度
                            text={element.areaName + this.state.zoom}　// 這個 Marker 的文字
                            key={index}
                            onClick={()=>console.log(index)}
                            />                            
                        )
                    }
                </GoogleMapReact>
            </div>
            <div className="col-md-12 col-lg-3 row text-left pt-2" >
                <div className="col-sm-12 col-md-6 col-lg-12 pb-2" >
                    <h5 onClick={()=>{this.setState({"mapquery":"武陵綠色隧道"});}}><b>2626小市集(鹿野)</b>
                    </h5>
                        <i className="fas fa-search-location fa-2x"></i>
                    <small className="text-muted">位於鹿野高台山腳下武陵綠色隧道的農村市集，每月雙週的週六下午二點到六點，故稱為2626市集</small>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-12 pb-2" >
                    <h5 onClick={()=>{this.setState({"mapquery":"小野柳風景管理處"});}}><b>夜訪小野柳(台東市區、富岡漁港)</b></h5>
                    <small className="text-muted">由小野柳風景管理處辦理的生態講解活動</small>
                </div>
            </div>
                {this.props.name===undefined?
                    
                <div className="col-md-12 col-lg-12 row">
                    <div className="col-lg-12">
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>美食</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>手作</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>慢活</Badge>
                    </div>
                    <div className="mx-auto p-3 col-md-6 text-left">
                    <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                    <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                    <Image className="img-fluid d-block" src="/banner-1.jpg" />
                    </div>
                    <div className="mx-auto p-3 col-md-6 text-left">
                    <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                    <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                    <Image className="img-fluid d-block" src="/banner-1.jpg" />
                    </div>
                    <div className="mx-auto p-3 col-md-6 text-left">
                    <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                    <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                    <Image className="img-fluid d-block" src="/banner-1.jpg" />
                    </div>
                    <div className="mx-auto p-3 col-md-6 text-left">
                    <h2 className="mb-1"><b>舊鐵道步道</b></h2>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                    <p>舊鐵道步道是早年進市區的火車鐵道，因改道後整理而成的自行車道，平時很多在地人喜歡在此散步或跑步。</p>
                    <Image className="img-fluid d-block" src="/banner-1.jpg" />
                    </div>
                </div>    
                    :                    
                <div className="col-md-12 col-lg-12 row">
                    <div className="mx-auto p-3 col-md-6 text-left">
                    <h2 className="mb-1"><b>{this.state.mapquery}</b></h2>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>景點</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>親子</Badge>
                    <Badge className="m-1 pt-1" variant="primary" onClick={()=>{this.handlerByKeyword()}} style={{cursor: "pointer"}}>放風</Badge>
                    <p>{this.state.mapquery + "介紹:…待補"}</p>
                    <Image className="img-fluid d-block" src="/banner-1.jpg" />
                    </div>
                </div>    
                }        
         </div>   
        )
    }
}

