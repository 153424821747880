import React, { Component } from 'react';
import {Image, Carousel, Badge} from 'react-bootstrap';


import {BannerCarousel} from '../../Components/Attractions';

  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }        
    }
    
    componentDidMount() {
        
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    render() {        
        return(
         <div className="mx-auto container-fluid row">
            <div className="col-md-12 col-lg-9 p-0">
                <BannerCarousel interval='3000'></BannerCarousel>
                <div className="mx-auto p-3 col-12 text-left">
                    <h3 className="border-bottom border-primary mb-2"><b>關於感覺</b></h3>                    
                    <h5>感覺台東是希望每一位旅客都能好好的體驗台東的美好，美麗的風景、自然的環境、舒服的空氣都是值得您來走走的。</h5>
                    <h5>我們提供的不只是乾淨舒服的住宿，也包含了台東大大小小資訊的提供。有著名小吃、特色料理、網美景點、當季活動、DIY手作、套裝行程、…等等，景點小吃問路任何事都可以與我們聯絡，加Line還會有景點地圖與美食地圖可供參考喔</h5>
                    <h5><mark><a href='http://line.me/ti/p/%40arw4940w' target='_blank' title="LINE連結">+感覺台東民宿官方Line帳號</a></mark></h5>
                    <h5></h5>
                    <h5></h5>
                    <h5></h5>
                </div>
                
                <div className="mx-auto p-3 col-12 text-left">
                    <h3 className="border-bottom border-primary mb-2"><b>關於停車</b></h3>                    
                    <h5>民宿前可停放1-2台汽車或6台重機或8-10台機車。</h5>
                    <h5>門口停放車位以<mark>預約</mark>為主，優先留給有幼童/長輩同行之旅客，若門口已停滿，沿路邊再往前一點的台東糖廠白色圍牆邊可停放車輛多台，包含遊覽車也可以，停車很方便與容易。</h5>
                    <h5><mark>若有幼童與長輩同行，建議預先通知我們，以利準備適合之房間與用品</mark></h5>
                </div>
                
                <div className="mx-auto p-3 col-12 text-left">
                    <h3 className="border-bottom border-primary mb-2"><b>關於租車</b></h3>                    
                    <h5>搭乘火車到台東是很舒服又方便的選擇，沿途風景美麗，台東租賃汽機車快速又便宜</h5>
                    <h5>旅宿有合作的租車行，租賃汽機車可以都有優惠，有需要都可以與我們詢問喔</h5>
                    <h5><mark>記得帶上未過期的駕照喔！租車行非公家機關可以隨時調閱您的駕照資料。忘了帶也可以用LINE傳喔</mark></h5>
                </div>
                
                <div className="mx-auto p-3 col-12 text-left">
                    <h3 className="border-bottom border-primary mb-2"><b>關於接駁</b></h3>                    
                    <h5>若有需要接駁，<mark>請提早至少一日預約</mark>以利安排，恕不接受臨時的接駁要求。提供到站時間即可，若火車有延誤也請通知我們喔~感謝。</h5>
                </div>
                
                <div className="mx-auto p-3 col-12 text-left">
                    <h3 className="border-bottom border-primary mb-2"><b>關於用餐</b></h3>                    
                    <h5>台東著名小吃、特色料理很多，但若行程繁多，想簡單用餐，民宿旁50公尺有「林記牛肉麵」、「桃子核的家印度料理」(需預約)、「味軒家常菜」，往7-11方向還有很多餐廳可以選擇，如「三點水誠食料理」、「匠心日式料理」、「梧桐素素食料理」、「玉子屋泡菜韓式料理」、「杜魯灣麵食館」
                    、「四四玖麵線」、「文子館雲泰美食」、「娜魯灣大飯店自助午晚餐」，最遠娜魯灣大飯店也不到1公里，散步即可</h5>
                    <h5>民宿附近還有叮哥茶飲(民宿內附有菜單與電話可訂購外送)、老東芳青草茶-東方店</h5>
                    <h5>民宿旁邊100公尺有蜜鄰超市-台東糖廠冰品部，可選購多樣零食、飲料、酒類等，營業至晚上9點，逾時後可再往前約150公尺到統一超商購買。統一超商旁有楊記地瓜酥-東方店、飲料店、阿亮鹹酥雞等小吃宵夜</h5>
                </div>
                
                <div className="mx-auto p-3 col-12 text-left">
                    <h3 className="border-bottom border-primary mb-2"><b>關於旅遊</b></h3>                    
                    <h5>台東的旅遊很方便，景點都在幹道上，市區以北的台九線稱為山線、市區以北的台十一線稱為海線、市區以南的台九線稱為南迴線，每個線都能玩上一天，景點多到無法逛完，會需要捨棄一些景點留到下次再來。</h5>
                    <h5>順著路走、順著路玩、順著路吃，回頭時也都會再經過這些景點，方便又簡單</h5>
                    <h5>山線一天、海線一天、南迴線一天、市區一天，基本款可以玩上四天喔，如果再加上套裝體驗行程、部落巡禮、手作DIY、綠島、蘭嶼，建議排上一週才能好好的感覺台東喔^_^</h5>
                    <h5><mark>什麼時間逛什麼景點最美，什麼季節玩什麼景點最剛好，這些我們都會跟你說~</mark></h5>
                </div>
                
                <div className="mx-auto p-3 col-12 text-left">
                    <h3 className="border-bottom border-primary border-bottom border-primary mb-2"><b>關於夜台東</b></h3>                    
                    <h5>晚上台東店家休息的早，一般晚上8~9點後很多正餐都開始休息了，所以建議旅客用餐安排早一些，也可以避免人潮，用餐後大約7~9點可以去鐵花村散步看燈飾、聽音樂、逛市集，逛完還可以再到旁邊正氣觀光夜市購買宵夜</h5>
                    <h5><mark>宵夜場</mark>-寶桑路上有好幾間夜間營業比較晚的店家，一次滿足你有點餓的心~「來點感性魯味」、「蘇天助素食麵」、「蓮玉湯圓」、「仙草屋」，附近還有「阿賓羊肉爐」，熱呼呼的羊肉湯在冷冷的夜暖你的胃，還有多樣熱炒可以選擇喔</h5>
                </div>
            </div>

            <div className="mx-auto p-3 col-12 col-md-3 text-center">
                <h3 className="border-bottom border-primary mb-2"><b>最佳推薦</b></h3>
                <h4>Booking.com 9.4分</h4>
                <h4>Agoda.com 9.3分</h4>
                <h4>Hotels.com 9.4分</h4>
                {/* <h4>Trivago.com 8.6分</h4> */}
                <h4>Google評論 4.8星</h4>
                <h4>Facebook 5星</h4>
                <h4>TripAdvisor 4.5星</h4>
                <h4>Asiayo.com 4.7星</h4>
                <h4>觀光局-好客民宿</h4>
                <h4>環保旅宿證書</h4>                    
                <h4>遊程規劃師證照</h4>                    
                <h6><small>記錄日期：<u>2021.2.18</u></small></h6>
            </div>     
        </div>   
        )
    }
}

