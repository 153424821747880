import React, { Component } from 'react';
import {Form, InputGroup,Button, FormControl} from 'react-bootstrap';
import {updateUserps, loginUser,queryPhotoSets} from '../../Module/AWS/DynamoDB/index';


  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }        
    }
    
    componentDidMount() {
        
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    render() {        
      console.log(this.props.get);
        return(
         <div className="container-fluid">
            旅宿登記
         </div>   
        )
    }
}

