import React, { Component } from 'react';
import {Badge, Carousel } from 'react-bootstrap';

const AttractionsData = [{areaName:'卑南豬血湯',latlng:{lat:22.77813181282595, lng:121.12582850578852},areaType:['特色','小吃'], areaDistance:0, Introduction:'濃郁湯頭、Q彈豬血，搭配小米腸，滿足好吃。', notice:'店前停車不方便，旁邊巷子裡有專用停車場喔',photo:'',link:'www.google.com.tw'},
{areaName:'好漁日鬼頭刀專賣',latlng:{lat:22.772255025017067, lng:121.13184375543722},areaType:['餐廳','特色'], areaDistance:0, Introduction:'專門以鬼頭刀料理店，店內乾淨明亮，店內還有很多不錯的農特產可以選購', notice:'',photo:'',link:''},
{areaName:'墾墨咖啡',latlng:{lat:22.77568823817769, lng:121.12838733443417},areaType:['咖啡館','特色'], areaDistance:0, Introduction:'台灣最佳25間咖啡館第13名，環境寬敞，甜點好吃', notice:'外觀不明顯，容易開過頭',photo:'',link:''},
{areaName:'林記牛肉麵',latlng:{lat:22.766857525400615, lng:121.13051589098443},areaType:['餐廳'], areaDistance:0, Introduction:'好吃牛肉麵/豬腳麵/餛飩麵/水餃', notice:'水餃很大顆，小心點太多',photo:'',link:''},
// {areaName:'桃子核的家印度料理',latlng:{lat:22.76675654260876, lng:121.13047699895371},areaType:['餐廳','特色'], areaDistance:0, Introduction:'特色印度料理', notice:'需預約',photo:'',link:''},
{areaName:'蜜鄰超市-糖廠冰品部',latlng:{lat:22.768115541988347, lng:121.12790513849528},areaType:['小吃'], areaDistance:0, Introduction:'農特產、飲料、零食、糖廠冰淇淋等等', notice:'',photo:'',link:''},
{areaName:'河南味牛肉麵',latlng:{lat:22.76425667365618, lng:121.13543472972619},areaType:['小吃','特色'], areaDistance:0, Introduction:'台東有名牛肉麵，湯頭香濃', notice:'對面路邊有很多停車格，平常停車方便',photo:'',link:''},
{areaName:'味軒家常菜',latlng:{lat:22.767592817057224, lng:121.12828328029491},areaType:['餐廳'], areaDistance:0, Introduction:'口味好，方便停車', notice:'',photo:'',link:''},
{areaName:'台東糖廠文創園區',latlng:{lat:22.76744624501263, lng:121.1272373455488},areaType:['手作','景點'], areaDistance:0, Introduction:'糖廠倉庫裡有很多不錯的工作坊與商場可以參觀、DIY等，還有阿度腳踏車可以租賃，後方即是舊鐵道步道，園區內還有多樣裝置藝術，適合拍照散步', notice:'',photo:'',link:''},
{areaName:'卡塔文化工作坊',latlng:{lat:22.767499233374274, lng:121.1279435884889},areaType:['手作','原住民'], areaDistance:0, Introduction:'原住民手作，現場可以DIY琉璃珠/串珠/頭目單口杯，也有很多漂亮的成品可以選購', notice:'',photo:'',link:''},
{areaName:'統一超商小7',latlng:{lat:22.768930206810683, lng:121.12617895112615},areaType:['便利'], areaDistance:0, Introduction:'', notice:'',photo:'',link:''},
{areaName:'路邊停車',latlng:{lat:22.767510211264216, lng:121.12920721782055},areaType:['便利'], areaDistance:0, Introduction:'白色圍牆處可停放多輛房車/休旅車/遊覽車，不佔車道，方便安全，返回旅宿約30公尺', notice:'',photo:'',link:''},
{areaName:'馬蘭舊車站-舊鐵道步道',latlng:{lat:22.765464827066072, lng:121.1289780447818},areaType:['景點'], areaDistance:0, Introduction:'馬蘭舊車站與舊鐵道步道有月台、鐵軌、木棧道，適合散步、單車等活動，可以在糖廠內的阿度腳踏車租賃單車或是報名導覽活動喔', notice:'',photo:'',link:''},
{areaName:'楊記地瓜酥(分店)',latlng:{lat:22.768944353353575, lng:121.12543071413458},areaType:['伴手禮'], areaDistance:0, Introduction:'台東十大伴手禮第一名，此為分店，中午12點開賣，賣完為止', notice:'',photo:'',link:''},
{areaName:'陳家麻糬',latlng:{lat:22.7793391654348, lng:121.12442390864557},areaType:['小吃'], areaDistance:0, Introduction:'多樣麻糬可供選購，麻糬Q彈好吃，還有旗魚鬆口味很特別', notice:'',photo:'',link:''},
{areaName:'中油直營加油站',latlng:{lat:22.766422700301202, lng:121.13767295505542},areaType:['便利'], areaDistance:0, Introduction:'', notice:'',photo:'',link:''},
{areaName:'維閣蛋卷',latlng:{lat:22.76550202247577, lng:121.13875182054988},areaType:['伴手禮'], areaDistance:0, Introduction:'厚實口感，紅烏龍、初鹿鮮奶、土雞蛋口味都很不錯', notice:'',photo:'',link:''},
{areaName:'老東芳青草茶',latlng:{lat:22.7688387505703, lng:121.12400146808906},areaType:['小吃'], areaDistance:0, Introduction:'台東青草茶老店，仙草茶、青草涼茶都很不錯', notice:'',photo:'',link:''},
{areaName:'均一中小學',latlng:{lat:22.7701579954946, lng:121.12611256999024},areaType:['便利'], areaDistance:0, Introduction:'華德福教育學校', notice:'',photo:'',link:''},
{areaName:'樂活早午餐',latlng:{lat:22.76705321018969, lng:121.13022226329507},areaType:['早餐'], areaDistance:0, Introduction:'特色手工蛋餅、鍋燒系列等都很好吃', notice:'',photo:'/Introduction/樂活早午餐菜單.jpg',link:''},
{areaName:'緣龍鹹酥雞',latlng:{lat:22.76323187358491, lng:121.13614719642551},areaType:['小吃'], areaDistance:0, Introduction:'很有特色的鹹酥雞店，還有搭配洛神，酸V酸V很不錯吃', notice:'',photo:'',link:''},
{areaName:'阿鋐炸雞(分店)',latlng:{lat:22.761909186136354, lng:121.1375755517478},areaType:['小吃'], areaDistance:0, Introduction:'古早味炸雞聞名，分店人潮較少比較好買喔', notice:'',photo:'',link:''}
]


//  抓取中心點以外多少m距離內的景點資料
function nearAttData(defCenter, defDist){
    var nearAttData = [];
    AttractionsData.forEach(element => {
        var dist = parseInt(Math.sqrt(((element.latlng.lat-defCenter.lat)**2) + ((element.latlng.lng-defCenter.lng)**2))*110000);
        if (dist <  defDist){ // 0.000001 = 大約1公里
            element.dist=dist;
            nearAttData.push(element);
        }
    });

    nearAttData.sort(function(a, b) {
        return a.dist > b.dist? 1 : -1;
    });

    return nearAttData;
}

//  景點分類製作標籤
class LabelAttData extends React.Component {
    render() {

        // 標籤分類
        var markertype=[];
        this.props.attData.forEach(element => { // 景點列表
            element.areaType.forEach(areaType => {  // 景點分類陣列
                var lock=true;
                markertype.forEach(element1 => {    //  label分類                    
                    if (areaType==element1){lock=false;}
                });
                if (lock){markertype.push(areaType)}
            });
        });

        return(
            <div className="col-lg-12">
                <h5>
                {                        
                    markertype.map((element,index)=>
                        <Badge key={index} className="m-1 pt-1" variant={this.props.selType===element?'success':'primary'} onClick={()=>{this.props.handlerByKeyword(element)}} style={{cursor: "pointer"}}>{element}</Badge>        
                    )                        
                }
                </h5>
            </div>
        )
    }
}

//  景點分類後列表
function ListAttData(tempNearAttData, selType) {

    // 過澸景點        
    var temAttData=[];
    if (selType===''){
        temAttData = tempNearAttData;
    }else{
        tempNearAttData.forEach(element => {
            element.areaType.forEach(areaType => {
                if (selType==areaType){
                    temAttData.push(element);
                }
            });
        });
    }
    return temAttData;
}

//  景點分類製作標籤
class BannerCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slideList:this.props.slideList?this.props.slideList:[{src:'https://dchlu2uso6pre.cloudfront.net/banner-1.jpg', title:'金鐏漁港', memo:'海如絲、天如畫'},
            {src:'https://dchlu2uso6pre.cloudfront.net/banner-2.jpg', title:'伽路蘭休憩區', memo:'山海之間'},
            {src:'https://dchlu2uso6pre.cloudfront.net/banner-3.jpg', title:'舊鐵道步道', memo:'散散步，你會發現這有多好'},
            {src:'https://dchlu2uso6pre.cloudfront.net/banner-4.jpg', title:'炸寒單', memo:'元宵必看，熱鬧剌激'}
            ],
            interval:this.props.interval?this.props.interval:3000
        }        
    }

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.slideList);
        // if (!nextProps.slideList===null){this.setstate({slideList:nextProps.slideList})}
    }

    render() {
        // console.log(this.props.slideList);
        const style_Caption = {
            right: '2%',
            bottom: '1%',
            // color:'black',
            textAlign: 'right',
            // maxWidth: '300px',
            left: 'auto',
            padding:'1px'
        }    
        return(
            <Carousel interval={this.state.interval}>
                {   
                    this.state.slideList.map((element,index)=>
                        <Carousel.Item key={index}>
                            <img
                            className="d-block w-100"
                            src={element.src}
                            alt="slide"
                            />
                            <Carousel.Caption style={style_Caption}>
                            <h3>{element.title}</h3>
                            <p>{element.memo}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                }                
            </Carousel>
        )
    }
}

export default AttractionsData;
export {nearAttData, LabelAttData, ListAttData, BannerCarousel};