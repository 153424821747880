import React, { Component } from 'react';
import {Image, Badge, Carousel, Dropdown } from 'react-bootstrap';

import {BannerCarousel} from '../../Components/Attractions';

  //直接輸出
export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // tlType:this.props.tlType,
            tlEdit:true,
            tlContents:[{typename:'img',img:[{src:'/banner-2.jpg', title:'伽路蘭休憩區', memo:'山海之間'},
            {src:'/banner-3.jpg', title:'舊鐵道步道', memo:'散散步，你會發現這有多好'},
                {src:'/banner-4.jpg', title:'炸寒單', memo:'元宵必看，熱鬧剌激'}]
            },
            {typename:'list',title:'伽路蘭休憩區', memo:'元宵必看，熱鬧剌激', mark:'山海之間'
            },
            {typename:'list',title:'伽路蘭休憩區', memo:'元宵必看，熱鬧剌激', mark:'山海之間'
            }]
        }        
    }
    
    componentDidMount() {
        
    }
    
    componentWillUnmount() {}

    // 追蹤更新props資料到state
    componentWillReceiveProps =(nextProps)=>{
        // console.log(nextProps.set);
    }

    mySwitchFunction = (content) => {
        switch (content.typename) {
            case 'img':
                // return tpImages(content.img);
                return <TPImages img={content.img}></TPImages>;
            case 'list':
                return ([
                    <div className="mx-auto p-3 col-12 text-left" key='0'>
                        <h3 className="border-bottom border-primary mb-2"><b>{content.title}</b></h3>
                        <h5>{content.memo}</h5>
                        <h5><mark>{content.mark}</mark></h5>
                    </div>
                ]);
            case 'new':
                return ([
                    <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        類型
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">圖片(輪播)</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">列表-記事</Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                ]);
        }
    }

    buttonClick = (cType) =>{
        switch (cType) {
            case 'new':
                var tem_tlc=this.state.tlContents;
                tem_tlc.push({typename:'new'});
                this.setState({tlContents:tem_tlc})
                break;
        
            default:
                break;
        }
    }

    render() {
        console.log(this.state.tlContents);
        return(
         <div className="mx-auto container-fluid row">
            {
                this.state.tlContents.map((element,index)=>
                    <div className={element.typename==='img'?"col-md-12 col-lg-9 p-0":"col-md-12 col-lg-6 p-0"} key={index}>
                    {/* {element.typename==='img'?:''} */}
                    {this.mySwitchFunction(element)}
                    {this.state.tlEdit?<button type="button" className="btn btn-success" onClick={()=>this.buttonClick(index)}>修改</button>:''}
                    </div>
                )
            }
            
            {this.state.tlEdit?<button type="button" className="btn btn-primary" onClick={()=>this.buttonClick('new')}>新增項目</button>:''}
         </div>   
        )
    }
}


//  照片版面輸出
class TPImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }        
    }

    render() {       
        return(
            <div>
                {this.props.img.length>1?<BannerCarousel interval={this.props.interval?this.props.interval:3000} slideList={this.props.img} ></BannerCarousel>:this.props.img.length===1?<Image className="img-fluid d-block" src={this.props.img[0].src} title={this.props.img[0].title} alt={this.props.img[0].memo} key='0' />:<div></div>}
            </div>
        )
    }
}

//  照片版面輸出
class TPListMemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }        
    }

    render() {       
        return(
            <div>
                {this.props.img.length>1?<BannerCarousel interval={this.props.interval?this.props.interval:3000} slideList={this.props.img} ></BannerCarousel>:this.props.img.length===1?<Image className="img-fluid d-block" src={this.props.img[0].src} title={this.props.img[0].title} alt={this.props.img[0].memo} key='0' />:<div></div>}
            </div>
        )
    }
}

// function tpImages(img) {
//     if (img.length>1){     
//         return (             
//                 <BannerCarousel interval='3000' slideList={img} ></BannerCarousel>
//         );
//     }else if (img.length=1){
//         return ([                                     
//                 <Image className="img-fluid d-block" src={img[0].src} title={img[0].title} alt={img[0].memo} />
//         ]);
//     }else{
//         return ([<div />]);
//     }
// }

export {TPImages, TPListMemo};